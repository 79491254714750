@import "variables";

@mixin container {
  width: $container;
  margin-right: auto;
  margin-left: auto;
}
@mixin containerNarrow {
  width: $containerNarrow;
  margin-right: auto;
  margin-left: auto;
}
@mixin hoverOp($op:0.5) {
  @include transition(opacity .2s linear);
  -webkit-backface-visibility: hidden;
  &:hover {
    @include opacity($op);
    text-decoration: inherit;
  }
}
@mixin hide-text() {
  text-indent: 200%;
  white-space: nowrap;
  overflow: hidden;
}
@mixin fs($fts, $lin: 14) {
  $rem: $fts / 10;
  $linS: $lin / $fts;
  font-size: #{$fts}px;
  font-size: #{$rem}rem;
  line-height: $linS;
}
@mixin en {
  font-family: SackersGothicStd-Light;
	font-weight: normal;
	font-style: normal;
}
@mixin sprite-background-legacy($name) {
  width: image-width(sprite-file($replace, $name));
  height: image-height(sprite-file($replace, $name));
  $xpos: nth(sprite-position($replace, $name), 1);
  $ypos: nth(sprite-position($replace, $name), 2);
  background-image: $replace-img;
  background-position: $xpos $ypos;
  background-repeat:no-repeat;
  $wbgz: image-width(sprite-path($replace));
  @include background-size($wbgz auto);
  display: block;
}
@mixin sprite-background($name) {
  width: image-width(sprite-file($replace, $name)) / 2;
  height: image-height(sprite-file($replace, $name)) / 2;
  $xpos: nth(sprite-position($replace, $name), 1) / 2;
  $ypos: nth(sprite-position($replace, $name), 2) / 2;
  background-image: $replace-img;
  background-position: $xpos $ypos;
  background-repeat:no-repeat;
  $wbgz: round(image-width(sprite-path($replace)) / 2);
  @include background-size($wbgz auto);
  display: block;
}
@mixin sprite-replace-legacy($name) {
  @include sprite-background-legacy($name);
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
@mixin sprite-replace($name) {
  @include sprite-background($name);
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
@mixin slickdots($width:10px,$height:10px) {
  text-align: center;
  li {
    display: inline-block;
    width: auto;
    padding: 0;
    margin: 0 8px;
    button {
      border: none;
      background-color: #b2b2b2;
      color: transparent;
      height: $height;
      width: $width;
      padding: 0;
      cursor: pointer;
      @include border-radius(5px);
    }
    &.slick-active {
      button {
        background-color: #4d4d4d;
      }
    }
  }
}

@import "variables";
@import "mixins";

@charset "UTF-8";

input[type="number"] {
  border: 1px solid #c5c5c5;
  display: inline-block;
  position: relative;
  @include fs(13,18);
  background-color: transparent;
  padding: 8px;
  transition: background .2s ease,border .2s ease;
  @media (min-width: $screen-sm-min) {
    padding: 10px;
    @include fs(15,25);
    &:hover {
      background-color: white;
    }
  }
  &:hover,&:focus {
    background-color: white;
  }
}
.module_recruit_newgraduate_application .links.single {
  justify-content: center;
  a {
    margin-top: unset;
  }
}
.module_form .form_table table td .select_wrap {
  padding: unset;
  border: unset;
  &::before, &::after {
    content: none;
  }
}
.module_form .form_table table td select {
  appearance: auto;
  margin-right: unset;
  padding: 12px;
  max-width: 100%;
  white-space: normal;
  word-wrap: break-word;
  border: 1px solid #c5c5c5;
  background-color: #ebebeb;
  @media (min-width: $screen-sm-min) {
    padding: 14px;
  }
}
.module_form .form_btns input {
  color: #000000;
}
.module_map address p.note {
  margin-left: 1em;
  text-indent: -1em;
}